import React, { ReactElement } from "react";
import Image from "next/image";

export default function WhatIsHomeWarranty(): ReactElement {
    return (
        <section className="flex flex-col lg:flex-row lg:gap-36 gap-16 lg:mt-28 mt-16   ">
            <div className="max-w-[574px]  mx-auto lg:mx-0 ">
                <h2 className="font-bold lg:text-3xl text-2xl lg:leading-[45px] text-[#1D2458]">
                    What Is a Home Warranty?
                </h2>
                <p className="mt-3   lg:text-lg text-base lg:leading-8 font-light">
                    A home warranty is a service that covers the expenses for
                    repairs or replacements of your household appliances and
                    systems. It's like an annual subscription that requires
                    renewal, but the coverage and terms differ depending on the
                    chosen plan.
                </p>
                <span className="w-[186px] h-[9px] block bg-[#F99D1C] mt-6"></span>
            </div>
            <div className="w-[300px] h-[200px] md:h-[290px] md:w-[499px] md:min-w-[499px] relative mx-auto lg:mx-0 ">
                <Image
                    src={
                        "/topchoicehomewarranty/assets/images/whatIsHomeWarranty.jpg"
                    }
                    alt="image"
                    layout="fill"
                />
            </div>
        </section>
    );
}
