import React, { ReactElement } from "react";

export default function NextAutoWarrantyLogo(): ReactElement {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="291"
            height="28"
            fill="none"
            viewBox="0 0 291 28"
        >
            <path
                fill="#2F6177"
                d="M7.1 11.9c-.9 0-1.6.7-1.6 1.6 0 .9.7 1.6 1.6 1.6.9 0 1.6-.7 1.6-1.6 0-.8-.7-1.6-1.6-1.6z"
            ></path>
            <path
                fill="url(#paint0_linear_21_39)"
                d="M4.8 16.5h-1v-6c1.3-2.8 2.6-5.5 4-8.2h17.8c0 .1.1.2.1.3l2.1-1.2c-.5-.8-1.4-1.4-2-1.4H7.5c-.8 0-1.8.8-2.3 1.8L3.4 5.7v.1c-.4-.3-.8-.4-1.2-.4C1 5.4 0 6.4 0 7.6c0 1 .7 1.9 1.6 2.1-.1.2-.1.4-.1.6v9.9c0 .8.6 1.4 1.4 1.4h2.8c.8 0 1.4-.6 1.4-1.4v-1.5H12c-.3-.8-.6-1.5-.9-2.3H4.8v.1z"
            ></path>
            <path
                fill="url(#paint1_linear_21_39)"
                d="M29.5 3.5s0 .1 0 0c-2.7 2.3-5 4.6-7.2 7.2-1.6 1.9-3.1 3.9-4.5 5.9-.5.7-1 1.5-1.5 2.3-.5.8-1 1.7-1.5 2.6-.3.3-.5.3-.8 0-.3-.9-.7-1.7-1-2.6-.3-.8-.6-1.5-.9-2.3-.4-1-.8-2-1.3-2.9-.5-1.3-1.3-2.3-2.6-2.3.5-1 1.6-2.3 3.4-1.8 1.6.5 2.6 2.1 3.4 4.1 1.5-1.7 2.9-3.3 4.3-4.7l2.3-2.3c3.2-2.9 7-6 11.6-6.2-.2 0-.6.4-.8.5-.9.8-1.9 1.7-2.9 2.5z"
            ></path>
            <path
                fill="#2F6177"
                d="M17.5 18.8h9.4v1.5c0 .8.6 1.4 1.4 1.4h2.8c.8 0 1.4-.6 1.4-1.4v-9.9c0-.2 0-.4-.1-.6 1.2-.1 2.1-1 2.1-2.2 0-1.2-1-2.2-2.2-2.2-.6 0-1.2.3-1.6.7-.1-.1-.1-.3-.2-.4-.2-.4-.4-.8-.6-1.3 0 0-7.9 7.1-12.4 14.4z"
            ></path>
            <path
                fill="url(#paint2_linear_21_39)"
                d="M20.6 11.9c-1.3 1.5-2.4 3-3.5 4.6l-1.2 1.8c-.4.7-.8 1.3-1.2 2-.2.2-.4.2-.6 0-.3-.7-.5-1.3-.8-2-.2-.6-.4-1.2-.7-1.8-.3-.8-.6-1.5-1-2.3-.3-1-1.6-2.7-2.6-2.7.4-.8 1.9-.9 3.3-.5 1.2.4 2 1.6 2.6 3.2l3.4-3.7"
            ></path>
            <path
                fill="#fff"
                d="M29.3 13.6c0-.9-.7-1.6-1.6-1.6-.9 0-1.6.7-1.6 1.6 0 .9.7 1.6 1.6 1.6.8 0 1.6-.7 1.6-1.6z"
            ></path>
            <path
                fill="#2F6177"
                d="M23.9 3.9H8.8L6.4 8.7h12c.8-.8 1.6-1.6 2.4-2.3 1.5-1.3 3.1-2.5 3.1-2.5zM54 14.6V2.5h3.5v19h-2.3L45 9.4v12.1h-3.5v-19h2.1L54 14.6zM74.5 14.2c0 .4 0 .9-.1 1.5H63.8c.2 1 .7 1.8 1.4 2.3.7.6 1.7.9 2.8.9 1.7 0 3.2-.5 4.6-1.4l1.3 2.4c-1.7 1.3-3.7 1.9-6.1 1.9-1.3 0-2.4-.2-3.4-.6-1-.4-1.8-1-2.4-1.7-.6-.7-1-1.5-1.3-2.3-.3-.8-.4-1.7-.4-2.7 0-2.1.7-3.9 2-5.2 1.4-1.4 3.1-2.1 5.2-2.1 2 0 3.7.6 4.9 1.9 1.5 1.2 2.1 2.9 2.1 5.1zm-6.9-4.3c-1 0-1.8.3-2.5.8-.7.6-1.1 1.3-1.3 2.3h7.4c-.1-1-.5-1.7-1.2-2.3-.6-.5-1.4-.8-2.4-.8zM84.2 14.4l4.5 7h-3.8L82 16.7l-2.9 4.7h-3.9l4.7-7.1-4.2-6.8h3.8l2.5 4.4 2.7-4.4h3.8l-4.3 6.9zM99.5 18l1.1 2.6c-1.1.8-2.4 1.2-4 1.2-3.2 0-4.8-1.8-4.8-5.4v-6h-2.3V7.5h2.4V2.6h3.3v4.9h4.7v2.8h-4.7v5.9c0 1.7.7 2.5 2 2.5.7 0 1.5-.2 2.3-.7z"
            ></path>
            <path
                fill="#ff9800"
                d="M121.2 21.4h-3.7l-1.6-4.3h-6.7l-1.6 4.3h-3.7l7.3-19h2.7l7.3 19zm-6.3-7.4l-2.3-6.5-2.4 6.5h4.7zM128.9 21.7c-1.9 0-3.5-.5-4.6-1.6-1.1-1.1-1.7-2.6-1.7-4.5V7.5h3.4v8.1c0 .9.3 1.7.8 2.3.5.6 1.2.8 2.1.8.9 0 1.6-.3 2.2-.8.5-.6.8-1.3.8-2.3V7.5h3.4v8.1c0 1.9-.6 3.4-1.7 4.5s-2.7 1.6-4.7 1.6zM147.3 18l1.1 2.6c-1.1.8-2.4 1.2-4 1.2-3.2 0-4.8-1.8-4.8-5.4v-6h-2.3V7.5h2.4V2.6h3.3v4.9h4.7v2.8H143v5.9c0 1.7.7 2.5 2 2.5.7 0 1.5-.2 2.3-.7zM149.1 14.4c0-2.1.7-3.8 2.1-5.2 1.4-1.4 3.2-2.1 5.4-2.1s4 .7 5.3 2.1c1.4 1.4 2.1 3.1 2.1 5.2s-.7 3.8-2.1 5.2c-1.4 1.4-3.2 2.1-5.3 2.1-2.2 0-4-.7-5.4-2.1-1.4-1.3-2.1-3.1-2.1-5.2zm11.5 0c0-1.2-.4-2.2-1.2-3-.8-.8-1.8-1.2-2.9-1.2-1.1 0-2.1.4-2.9 1.2-.8.8-1.2 1.8-1.2 3s.4 2.2 1.2 3c.8.8 1.8 1.2 2.9 1.2 1.1 0 2.1-.4 2.9-1.2.8-.8 1.2-1.8 1.2-3z"
            ></path>
            <path
                fill="#2F6177"
                d="M176.4 21.4h-2.8l-6.3-19h3.8l4 13 4.5-13h2.1l4.6 13 3.9-13h3.8l-6.3 19h-2.8l-4.3-12.8-4.2 12.8zM205.3 9.3V7.5h3.2v13.9h-3.2v-1.7c-1.2 1.4-2.7 2.1-4.5 2.1-2 0-3.7-.7-4.9-2.1-1.3-1.4-1.9-3.2-1.9-5.3 0-2.1.6-3.8 1.9-5.2 1.3-1.4 3-2.1 4.9-2.1 1.7.1 3.2.8 4.5 2.2zm0 5.1c0-1.2-.4-2.2-1.2-3-.8-.8-1.8-1.2-2.9-1.2-1.2 0-2.2.4-2.9 1.2-.8.8-1.1 1.8-1.1 3s.4 2.2 1.1 3c.8.8 1.8 1.2 2.9 1.2 1.2 0 2.2-.4 2.9-1.2.9-.8 1.2-1.8 1.2-3zM215.2 21.4h-3.3V7.5h3.2v1.9c.9-1.5 2.1-2.3 3.9-2.3.6 0 1.1.1 1.7.3l-.3 3.2c-.6-.2-1.1-.3-1.6-.3-1.1 0-1.9.4-2.6 1.1-.6.7-.9 1.8-.9 3.2v6.8h-.1zM225.8 21.4h-3.3V7.5h3.2v1.9c.9-1.5 2.1-2.3 3.9-2.3.6 0 1.1.1 1.7.3l-.3 3.2c-.6-.2-1.1-.3-1.6-.3-1.1 0-1.9.4-2.6 1.1-.6.7-.9 1.8-.9 3.2v6.8h-.1zM243.2 9.3V7.5h3.2v13.9h-3.2v-1.7c-1.2 1.4-2.7 2.1-4.5 2.1-2 0-3.7-.7-4.9-2.1-1.3-1.4-1.9-3.2-1.9-5.3 0-2.1.6-3.8 1.9-5.2 1.3-1.4 3-2.1 4.9-2.1 1.8.1 3.3.8 4.5 2.2zm.1 5.1c0-1.2-.4-2.2-1.2-3-.8-.8-1.8-1.2-2.9-1.2-1.2 0-2.2.4-2.9 1.2-.8.8-1.1 1.8-1.1 3s.4 2.2 1.1 3c.8.8 1.8 1.2 2.9 1.2 1.2 0 2.2-.4 2.9-1.2.8-.8 1.2-1.8 1.2-3zM253.2 21.4h-3.3V7.5h3.3v1.9c.4-.7.9-1.3 1.7-1.7.8-.4 1.7-.6 2.7-.6 1.5 0 2.8.5 3.9 1.5s1.6 2.4 1.6 4.3v8.5h-3.4v-7.6c0-1.1-.3-2-.8-2.7-.5-.6-1.3-1-2.2-1-1 0-1.8.3-2.4 1-.6.7-.9 1.6-.9 2.7v7.6h-.2zM274.8 18l1.1 2.6c-1.1.8-2.4 1.2-4 1.2-3.2 0-4.8-1.8-4.8-5.4v-6h-2.3V7.5h2.4V2.6h3.3v4.9h4.7v2.8h-4.7v5.9c0 1.7.7 2.5 2 2.5.7 0 1.5-.2 2.3-.7zM283.8 16.9l3.3-9.4h3.6l-5.6 14.8c-.7 1.7-1.5 3-2.4 3.8-.9.8-2 1.2-3.3 1.2-1.1 0-1.9-.2-2.6-.7l.5-2.8c.6.3 1.2.5 1.8.5 1.1 0 2-.8 2.7-2.3l.3-.8-5.9-13.7h3.9l3.7 9.4z"
            ></path>
            <defs>
                <linearGradient
                    id="paint0_linear_21_39"
                    x1="5.865"
                    x2="27.325"
                    y1="3.67"
                    y2="21.567"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#37778B"></stop>
                    <stop offset="0.359" stopColor="#2E6076"></stop>
                </linearGradient>
                <linearGradient
                    id="paint1_linear_21_39"
                    x1="7.983"
                    x2="31.092"
                    y1="17.263"
                    y2="7.778"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#E07539"></stop>
                    <stop offset="0.98" stopColor="#ff9800"></stop>
                </linearGradient>
                <linearGradient
                    id="paint2_linear_21_39"
                    x1="15.19"
                    x2="14.85"
                    y1="22.148"
                    y2="12.547"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#fff" stopOpacity="0.5"></stop>
                    <stop offset="0.98" stopColor="#fff" stopOpacity="0"></stop>
                </linearGradient>
            </defs>
        </svg>
    );
}
