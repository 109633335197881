import React, { ReactElement } from "react";
import Image from "next/image";

export default function HowIsHomeWarranty(): ReactElement {
    return (
        <section className="flex flex-col lg:flex-row lg:gap-44 gap-16 lg:mt-24 mt-16   ">
            <div className="max-w-[574px]  mx-auto lg:mx-0 order-1 lg:order-2">
                <h2 className="font-bold lg:text-3xl text-2xl lg:leading-[45px] text-[#1D2458]">
                    How Is a Home Warranty Different From Homeowner's Insurance?
                </h2>
                <p className="mt-3   lg:text-lg text-base lg:leading-8 font-light">
                    A home warranty covers appliances and systems, unlike
                    homeowner's insurance, which only covers your home's
                    structure and property. Though it doesn't replace
                    homeowner's insurance, it provides extra protection. New
                    homeowners may already have some coverage through
                    manufacturer warranties.
                </p>
                <span className="w-[186px] h-[9px] block bg-[#F99D1C] mt-6"></span>
            </div>
            <div className="w-[300px] h-[200px] md:h-[290px] md:w-[499px] md:min-w-[499px] relative mx-auto lg:mx-0 order-2 lg:order-1 lg:mt-5">
                <Image
                    src={
                        "/topchoicehomewarranty/assets/images/howIsHomeWarranty.jpg"
                    }
                    alt="image"
                    layout="fill"
                />
            </div>
        </section>
    );
}
