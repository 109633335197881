import React, { ReactElement } from "react";
import Image from "next/image";
export default function WhatBenefits(): ReactElement {
    return (
        <section className="flex flex-col lg:flex-row   lg:mt-40 mt-20 rounded-[20px] bg-[#F7F7F7] lg:px-14 px-5 py-11 relative gap-10 lg:gap-0">
            <div className="lg:max-w-[calc(100%-466px)]">
                <h2 className="font-bold lg:text-3xl text-2xl lg:leading-[45px]  ">
                    Why Should You Get a Home Warranty?
                </h2>
                <p className="mt-2 lg:max-w-[515px]  lg:text-lg text-base font-light">
                    A home warranty protects household appliances and systems
                    from unexpected repairs and breakdowns. It's ideal for
                    homeowners who lack repair skills or time for maintenance.
                    It also offers peace of mind by covering unexpected
                    breakdowns.
                </p>
            </div>
            <div className="lg:w-[410px] lg:min-w-[410px] lg:h-[329px] w-[300px] h-[200px]  lg:absolute lg:-top-20 lg:right-[27px]  mx-auto lg:mx-0 relative">
                <Image
                    src={
                        "/topchoicehomewarranty/assets/images/whatBenefits.png"
                    }
                    alt="Image"
                    layout="fill"
                />
            </div>
        </section>
    );
}
